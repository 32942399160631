// vuex的getter
import variables from '@/styles/variables.module.scss'
const getters={
    token:state =>state.user.token,
    userInfo:state=>state.user.userInfo,
    hasUserInfo:state=>{
        return JSON.stringify(state.user.userInfo) != '{}'
    },
    cssVar:state=>variables,
    sidebarOpened:state=>state.app.sidebarOpened,
    language:state=>state.app.language,
    mainColor: state => state.theme.mainColor,
    tagsViewList: state => state.app.tagsViewList,
}
export default getters