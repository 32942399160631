<template>
    <div @click="onToggle">
        <svg-icon :icon="isFullscreen?'exit-fullscreen':'fullscreen'"></svg-icon>
    </div>
</template>

<script setup>
import {ref,onMounted,onUnmounted} from 'vue'
import screenfull from 'screenfull'
const isFullscreen=ref(false)
const onToggle=()=>{
    screenfull.toggle()
    console.log(isFullscreen.value);
}
const change=()=>{
    isFullscreen.value=screenfull.isFullscreen
}
onMounted(()=>{
    screenfull.on('change',change)
})
onUnmounted(()=>{
    screenfull.on('change',change)
})
</script>

<style lang="scss" scoped>

</style>