// axios请求模块

import axios from "axios";
import { ElMessage } from "element-plus";
import store from '@/store/index.js'
import { isCheckTimeout } from '@/utils/auth'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 5000,
});

// 请求拦截器
service.interceptors.request.use((config) => {
  config.headers.icode = "helloqianduanxunlianying";
  if(store.getters.token){
    config.headers.Authorization=`Bearer ${store.getters.token}`
        // 配置接口国际化
    config.headers['Accept-Language'] = store.getters.language
    if (isCheckTimeout()) {
      // 登出操作
      store.dispatch('user/logout')
      return Promise.reject(new Error('token 失效'))
    }
  }
  return config;
},error=>{
  return Promise.reject(error)
});

// 响应拦截器
service.interceptors.response.use((response) => {
    const { success, message, data } = response.data;
    if (success) {
      // ElMessage.success("登录成功");
      return data;
    } else {
      // 业务错误
      ElMessage.error(message); // 提示错误消息
      return Promise.reject(new Error(message));
    }
  },(error) => {
    if(error.response && error.response.data && error.response.data.code === 401){
      // token超时
      store.dispatch('user/logout')
    }
    ElMessage.error(error.message); // 提示错误消息
    return Promise.reject(error);
  }
);

export default service;
