import { LANG,TAGS_VIEW } from '@/constant'
import { getItem, setItem } from '@/utils/storage'

export default {
    namespaced:true,
    state:{
        sidebarOpened:true,
        language: getItem(LANG) || 'zh',
        tagsViewList: getItem(TAGS_VIEW) || []
    },
    mutations:{
        triggerSidebarOpened(state){
            state.sidebarOpened=!state.sidebarOpened
        },
        setLanguage(state, lang) {
            setItem(LANG, lang)
            state.language = lang
        },
        addTagsViewList(state, tag) {
            const isFind = state.tagsViewList.find(item => {
              return item.path === tag.path
            })
          // 处理重复
            if (!isFind) {
              state.tagsViewList.push(tag)
              setItem(TAGS_VIEW, state.tagsViewList)
            }
        },
        changeTagsView(state, { index, tag }) {
            state.tagsViewList[index] = tag
            setItem(TAGS_VIEW, state.tagsViewList)
        },
        removeTagsView(state, payload) {
            if (payload.type === 'index') {
              state.tagsViewList.splice(payload.index, 1)
            //   return
            } else if (payload.type === 'other') {
              state.tagsViewList.splice(
                payload.index + 1,
                state.tagsViewList.length - payload.index + 1
              )
              state.tagsViewList.splice(0, payload.index)
            } else if (payload.type === 'right') {
              state.tagsViewList.splice(
                payload.index + 1,
                state.tagsViewList.length - payload.index + 1
              )
            }
            setItem(TAGS_VIEW, state.tagsViewList)
        },
        dragTagsView(state, { newIndex, oldIndex }) {
          const moveTags = (arr, oldndex, newIndex) => {
            // 获取要移动的元素
            const element = arr[oldndex];
            //  从原数组中删除要移动的元素
            arr.splice(oldndex, 1);
            // 将要移动的元素插入到目标位置
            arr.splice(newIndex, 0, element);
            return arr;
          }
          state.tagsViewList=moveTags(state.tagsViewList,oldIndex,newIndex)
          setItem("tagsView", state.tagsViewList);
        },
    }
}