<template>
  <div class="">
    <div class="logo-container">
      <el-avatar :size='logoHeight' shape="square" src="https://m.imooc.com/static/wap/static/common/img/logo-small@2x.png" />
      <h1 class="logo-title" v-if="$store.getters.sidebarOpened">{{ $t('msg.route.headerTitle') }}</h1>
    </div>
    <el-scrollbar>
      <sidebar-menu></sidebar-menu>
    </el-scrollbar>
  </div>
</template>

<script setup>
import SidebarMenu from './SidebarMenu'
import {ref} from 'vue'
const logoHeight=42
</script>

<style lang="scss" scoped>
.logo-container {
  // height: v-bind(logoHeight)+'px';
  height: 44px;
  padding: 10px 0 22px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .logo-title {
    margin-left: 20px;
    color: #fff;
    font-weight: 600;
    line-height: 50px;
    font-size: 16px;
    white-space: nowrap;
  }
}
</style>