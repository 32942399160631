// 存储常量

// token
export const TOKEN = 'token'

// token 时间戳
export const TIME_STAMP = 'timeStamp'
// 超时时长(毫秒) 两小时
export const TOKEN_TIMEOUT_VALUE = 1000 * 60 * 60 *24

export const LANG = 'language'

// 主题色保存的 key
export const MAIN_COLOR = 'mainColor'
// 默认色值
export const DEFAULT_COLOR = '#409eff'

// tags
export const TAGS_VIEW = 'tagsView'