// vuex 操作user信息
import { login,getUserInfo } from "@/api/sys";
import md5 from "md5";
import { setItem, getItem,removeItem } from "@/utils/storage";
import { setTimeStamp } from '@/utils/auth'
import { TOKEN } from "@/constant";
import router,{ resetRouter } from "@/router";

export default {
  namespaced: true,
  /**
   * mutations中操作state
   * actions中使用commit操作mutations
   * 组件中使用dispatch操作actions
   */
  state: {
    token: getItem(TOKEN) || "",
    userInfo:{}
  },
  // actions: {
  //   login(context, userInfo) {
  //     const { username, password } = userInfo;
  //     return new Promise((resolve, reject) => {
  //       login({
  //         username,
  //         password: md5(password),
  //       }).then((data) => {
  //         context.commit("setToken", data.token);
  //           router.push("/");
  //           resolve();
  //         }).catch((err) => {
  //           console.log(err);
  //           reject();
  //         });
  //     });
  //   },
  // },
  actions: {
    async login({ commit }, userInfo) {
      const { username, password } = userInfo;
      try {
        const data = await login({ username, password:md5(password) });
        commit("setToken", data.token);
        // 保存登录时间
        setTimeStamp()
        router.push("/");
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async getUserInfo(context){
      const res=await getUserInfo()
      context.commit('setUserInfo',res)
      return res
    },
    logout(context){
      context.commit('setToken',''),
      context.commit('setUserInfo','')
      removeItem('token')
      removeItem('timeStamp')
      resetRouter()
      router.push('/login')
    }
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
      setItem(TOKEN, token);
    },
    setUserInfo(state,userInfo){
      state.userInfo=userInfo
    }
  },
};
