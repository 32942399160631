import router from "./router";
import store from "./store";

// 白名单
const whiteList = ["/login"];
/**
 * 路由前置守卫
 */
router.beforeEach(async (to, from, next) => {
  // 存在 token ，进入主页
  // if (store.state.user.token) {
  // 快捷访问
  if (store.getters.token) {
    if (to.path === "/login") {
      next("/");
    } else {
      // console.log(store.getters.userInfo);
      if (!store.getters.hasUserInfo) {
        // 触发获取用户信息的 action，并获取用户当前权限
        const { permission } = await store.dispatch("user/getUserInfo");
        // 处理用户权限，筛选出需要添加的权限
        const filterRoutes = await store.dispatch("permission/filterRoutes",permission.menus);
        // 利用 addRoute 循环添加
        filterRoutes.forEach((item) => {
          router.addRoute(item);
        });
        // 添加完动态路由之后，需要在进行一次主动跳转
        return next(to.path);
      }
      next();
    }
  } else {
    // 没有token的情况下，可以进入白名单
    if (whiteList.indexOf(to.path) > -1) {
      next();
    } else {
      next("/login");
    }
  }
});
