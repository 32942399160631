import { createApp } from 'vue'
import i18n from '@/i18n/index.js'
import App from './App.vue'
const app = createApp(App)

// vue-router
import router from './router'
app.use(router)


//vuex
import store from './store'
app.use(store)

// 导入ElementPlus
import ElementPlus from 'element-plus'
import 'element-plus/lib/theme-chalk/index.css'
app.use(ElementPlus)

// 导入全局样式
import './styles/index.scss'

// 导入 svgIcon
import installIcons from '@/icons'
installIcons(app)

// 导入路由守卫
import '@/permission.js'

//导入国际化
app.use(i18n)

// 过滤器
import installFilter from '@/filters'
installFilter(app)

// 自定义指令
import installDirective from '@/directives'
installDirective(app)

// 挂载app
app.mount('#app')
