<template>
    <div class="tags-view-container">
        <div ref="dropRef">
            <router-link class="tags-view-item" :class="isActive(tag) ? 'active' : ''" :style="{
                backgroundColor: isActive(tag) ? $store.getters.cssVar.menuBg : '',
                borderColor: isActive(tag) ? $store.getters.cssVar.menuBg : ''
            }"
                v-for="(tag, index) in $store.getters.tagsViewList" :key="tag.fullPath" :to="{ path: tag.fullPath }"
                @contextmenu.prevent="openMenu($event, index)">
                {{ tag.title }}
                <!-- <i v-show="!isActive(tag)" class="el-icon-close" @click.prevent.stop="onCloseClick(index)"/> -->
                <i class="el-icon-close" @click.prevent.stop="onCloseClick(index)" />
            </router-link>
        </div>
        <context-menu v-show="visible" :style="menuStyle" :index="selectIndex"></context-menu>
    </div>
</template>
  
<script setup>
import { ref, reactive, watch, onMounted, nextTick } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import ContextMenu from './ContextMenu.vue'
import Sortable from 'sortablejs'

const route = useRoute()
const router = useRouter()
import { useStore } from 'vuex'
const store = useStore()

/**
 * 是否被选中
 */
const isActive = tag => {
    return tag.path === route.path
}

/**
 * 关闭 tag 的点击事件
 */
const onCloseClick = index => {
    const tagsViewList = store.getters.tagsViewList;
    const currPath = tagsViewList[index].fullPath
    if( currPath == '/profile'){
        return
    }
    if (currPath === route.fullPath && tagsViewList.length != 1) {
        router.push(tagsViewList[index - 1].fullPath)
    }
    if (tagsViewList.length === 1 ) {
        router.push('/profile')
    }
    store.commit('app/removeTagsView', { type: 'index', index: index })
}
// contextMenu 相关
const selectIndex = ref(0)
const visible = ref(false)
const menuStyle = reactive({
    left: 0,
    top: 0
})
/**
 * 展示 menu
 */
const openMenu = (e, index) => {
    const path = store.getters.tagsViewList[index].path
    router.push(path)
    const { x, y } = e
    menuStyle.left = x + 'px'
    menuStyle.top = y + 'px'
    selectIndex.value = index
    visible.value = true
}
/**
 * 关闭 menu
 */
const closeMenu = () => {
    visible.value = false
}

/**
 * 监听变化
 */
watch(visible, val => {
    if (val) {
        document.body.addEventListener('click', closeMenu)
    } else {
        document.body.removeEventListener('click', closeMenu)
    }
})

const dropRef = ref(null)
const sortDrag = () => {
    nextTick(() => {
        let el = dropRef.value
        new Sortable(el, {
            animation: 100,
            onEnd: () => {
                console.log('位置调整成功');
            },
            onUpdate: (el) => {
                console.log(el)
                const newIndex = el.newIndex
                const oldIndex = el.oldIndex
                store.commit('app/dragTagsView', { newIndex, oldIndex })
            }
        })
    })
}
onMounted(() => {
    sortDrag()
})
</script>
  
<style lang="scss" scoped>
.tags-view-container {
    height: 43px;
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #d8dce5;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 3px 0 rgba(0, 0, 0, 0.04);

    .tags-view-item {
        display: inline-block;
        position: relative;
        cursor: pointer;
        height: 30px;
        line-height: 30px;
        border: 1px solid #d8dce5;
        color: #495060;
        background: #fff;
        padding: 0 8px;
        font-size: 12px;
        margin-left: 5px;
        margin-top: 7px;

        &:first-of-type {
            margin-left: 15px;
        }

        &:last-of-type {
            margin-right: 15px;
        }

        &.active {
            color: #fff;

            &::before {
                content: '';
                background: #fff;
                display: inline-block;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                position: relative;
                margin-right: 4px;
            }
        }

        // close 按钮
        .el-icon-close {
            width: 16px;
            height: 16px;
            line-height: 10px;
            vertical-align: 2px;
            border-radius: 50%;
            text-align: center;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            transform-origin: 100% 50%;

            &:before {
                transform: scale(0.6);
                display: inline-block;
                vertical-align: -3px;
            }

            &:hover {
                background-color: #b4bccc;
                color: #fff;
            }
        }

    }
}
</style>