<template>
  <el-config-provider :locale="$store.getters.language == 'zh'?zhCn:en">
    <router-view></router-view>
  </el-config-provider>
</template>

<script setup>
import { watchSwitchLang } from '@/utils/i18n'
import {useStore} from 'vuex'
const store=useStore()
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
import en from 'element-plus/lib/locale/lang/en'
/**
 * 监听 语言变化，重新获取个人信息
 */
watchSwitchLang(() => {
  if (store.getters.token) {
    store.dispatch('user/getUserInfo')
  }
})
</script>

<style>
</style>
